import { Controller } from "stimulus";
import { submitForm } from "init/ajax";
import { parentModal } from "../modal/utils";

export default class extends Controller {
  connect() {}

  async submit(event) {
    event.preventDefault();

    const form = event.target;
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let responseHTML = await submitForm(form);

      parentModal(this.element).replaceWith(
        responseHTML.querySelector(".modal") || ""
      );
    }
  }
}
