import { setCookie, getCookie } from "init/cookie";

export class CheckoutStorageHandler {
  constructor() {
    this.checkoutFields = 'checkout_fields';
    this.data = JSON.parse(localStorage.getItem(this.checkoutFields)) || {};
    this.originalCountryCodeKey = 'original_country_code';
    this.countryCodeKey = 'country_code';
  }

  getData() {
    return this.data;
  }

  setData(newData) {
    this.data = newData;
    localStorage.setItem(this.checkoutFields, JSON.stringify(this.data));
  }

  resetAndEmpty() {
    this.emptyData();
    this.resetCountryCookie();
  }

  emptyData() {
    localStorage.removeItem(this.checkoutFields);
  }

  resetCountryCookie() {
    const originalCountryCode = localStorage.getItem(this.originalCountryCodeKey);
    const currentCountryCode = getCookie(this.countryCodeKey);

    if (!originalCountryCode || !currentCountryCode) return;
    
    if (originalCountryCode !== currentCountryCode) {
      setCookie(this.countryCodeKey, originalCountryCode);
    }

    localStorage.removeItem(this.originalCountryCodeKey);
  }

  updateCookies(newCountryCode) {
    if (!localStorage.getItem(this.originalCountryCodeKey)) {
      localStorage.setItem(this.originalCountryCodeKey, getCookie(this.countryCodeKey));
    } else {
      setCookie(this.countryCodeKey, newCountryCode);
    }
  }
}
