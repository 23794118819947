import { Controller } from "stimulus";

const LOADED_CLASS = "lazy-image--loaded";
export default class extends Controller {
  initialize() {
    if (this.observable) {
      this.intersectionObserver = new IntersectionObserver(
        entries => this.processIntersectionEntries(entries),
        // Load images when they come within 100px of the viewport
        { rootMargin: "100px" }
      );
    }
  }

  connect() {
    if (this.intersectionObserver) {
      this.intersectionObserver.observe(this.element);
    } else {
      // Just load immediately on IE
      this.load();
    }
  }

  disconnect() {
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.element);
    }
  }

  load() {
    // Do nothing if already loaded
    if (this.element.classList.contains(LOADED_CLASS)) {
      return;
    }

    if (this.element.dataset.srcset) {
      this.element.srcset = this.element.dataset.srcset;
    }

    if (this.element.dataset.src) {
      this.element.src = this.element.dataset.src;
    }

    if (this.element.dataset.alt) {
      this.element.alt = this.element.dataset.alt;
    }

    const $el = this.element;
    this.element.onload = () => $el.classList.add(LOADED_CLASS);

    const event = new CustomEvent("lazy-loaded", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0 || entry.isIntersecting) {
        // Note: assuming event.target == this.element since we're only
        // observing one element
        this.intersectionObserver.unobserve(this.element);

        this.load();
      }
    });
  }

  observable() {
    return (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    );
  }
}
