import { Controller } from "stimulus";
import { loadModalUrl } from "../modal/utils";

const links = document.querySelectorAll(".menu a");
const footer = document.querySelector("footer");

export default class extends Controller {
  static targets = ["destinations", 'destinationLabel', 'destinationButton'];

  selections = []

  connect() {
    this.scrollY = Math.round(window.scrollY);
    this.blockTransition();
    this.checkHeader();
    window.onresize = this.resize.bind(this);
    window.onload = this.load.bind(this);
    window.onscroll = this.checkHeader.bind(this);
    this.menu = this.element.querySelector("nav.menu");
    this.hamburger = this.element.querySelector(".hamburger");
    this.nan_container = document.querySelector(".nav_container");

    document.addEventListener('click', (event) => {
      const destinationsElement = this.nan_container.querySelector("nav.destinations");
      if (destinationsElement) {
        if (!this.destinationsTarget.contains(event.target) && this.destinationsTarget.classList.contains("open")) {
          this.destinationsTarget.classList.remove("open");
          this.destinationsTarget
              .querySelector(".arr-down-main")
              .classList.remove("openSelect");
        }
      }
    });



    if (this.hasDestinationLabelTarget) {
      this.currentDestinationLabelContent = this.destinationLabelTarget.textContent
      this.selections = [...this.element.querySelectorAll('.options ul li.active')].map((o) => o.dataset.value)
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("mouseenter", this.linkHover);
    }
    this.menu.addEventListener("mouseleave", this.menuOut);
  }

  resize() {
    this.blockTransition();
    this.closeMenu();
  }

  load() {
    // eslint-disable-next-line no-undef
    const menuHidden = Site.getBreakpoint().match(/desktop/) ? "false" : "true";
    this.menu.setAttribute("aria-hidden", menuHidden);
    this.hamburger.setAttribute("aria-expanded", "false");
  }

  checkHeader() {
    const scrollPosition = Math.round(window.scrollY);
    const globalBanner = document.querySelector(".global-banner");
    if (scrollPosition > 100) {
      this.element.classList.add("minimized");
      if (globalBanner) {
        globalBanner.classList.add("minimized");
      }
    } else {
      this.element.classList.remove("minimized");
      if (globalBanner) {
        globalBanner.classList.remove("minimized");
      }
    }

    // Only headroom if we're scrolled below header & booking bar,
    //   less 38px for aesthetic padding and the error bar.
    const headroomTarget = Math.max(this.scrollY, 100);
    if (scrollPosition > headroomTarget)
      document.body.classList.add("headroom");
    else document.body.classList.remove("headroom");

    this.scrollY = scrollPosition;
  }

  menuOut() {
    this.parentNode.parentNode.classList.remove("active");
  }

  linkHover() {
    this.parentNode.classList.add("active");
  }

  blockTransition() {
    clearTimeout(this.timer);
    document.body.classList.add("notransition");
    this.timer = setTimeout(() => {
      document.body.classList.remove("notransition");
    }, 500);
  }

  menuClosed() {
    footer.style.zIndex = 0;
  }

  closeMenu() {
    this.element.classList.remove("open");
    this.menu.setAttribute("aria-hidden", "true");
    this.hamburger.setAttribute("aria-expanded", "false");
    this.menu.addEventListener("transitionend", this.menuClosed);
    document.body.style.overflowY = "auto";
    this.nan_container.style.display = 'flex'
    this.setDisplayTabletMenu('none');
  }

  openMenu() {
    this.element.classList.add("open");
    this.menu.setAttribute("aria-hidden", "false");
    this.hamburger.setAttribute("aria-expanded", "true");
    footer.style.zIndex = -1;
    this.menu.removeEventListener("transitionend", this.menuClosed);
    document.body.style.overflow = "hidden";
    this.setDisplayTabletMenu('flex');
  }

  setDisplayTabletMenu(style) {
    if (this.element.offsetWidth + 15 < 1200) {
      this.nan_container.style.display = style
    }
  }

  toggleDestinationMenu() {
    this.destinationsTarget.classList.toggle("open");
    this.destinationsTarget
      .querySelector(".arr-down-main")
      .classList.toggle("openSelect");
  }

  toggle() {
    if (this.element.classList.contains("open")) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  closeSubmenu(e) {
    e.currentTarget.classList.remove("open");
  }

  openSubmenu(e) {
    e.currentTarget.classList.add("open");
  }

  toggleSubmenu(e) {
    if (e.target.parentNode == e.currentTarget) e.preventDefault();
    e.currentTarget.classList.toggle("open");
  }

  async openAccountModal(event) {
    event.preventDefault();
    loadModalUrl(event.target.href);
  }

  toggleDestination(e) {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.classList.toggle('active');

    const currentSelectionIndex = this.selections.findIndex((i) => i === e.currentTarget.dataset.value);

    if (currentSelectionIndex !== -1) {
      this.selections.splice(currentSelectionIndex, 1);
    } else {
      this.selections.push(e.currentTarget.dataset.value);
    }
    this.updateDestinationLabel();
  }

  updateDestinationLabel() {
    if (this.selections.length === 0) {
      this.destinationButtonTarget.classList.remove('select-button--active');
      this.destinationLabelTarget.textContent = this.currentDestinationLabelContent
    } else {
      this.destinationButtonTarget.classList.add('select-button--active');
      this.destinationLabelTarget.textContent = `${this.currentDestinationLabelContent} (${this.selections.length})`;

    }
  }

  submitDestination() {
    const searchParams = new URLSearchParams(window.location.search);
    const urlParams = new URLSearchParams('');

    searchParams.delete("resorts");
    searchParams.delete("destinations");
    if (this.selections.length > 0){
      urlParams.set("destinations", this.selections.join());
    }

    searchParams.forEach((value, key) => urlParams.set(key, value) );

    window.location.href = `/stay?${decodeURIComponent(urlParams)}`;
  }
}
