import { Controller } from "stimulus";

export default class extends Controller {
  // Stimulus Lifecycle
  // --------------------------------------------------------------------------
  // This fires when this controller is attached to a DOM element.
  connect = () => {
    // Check if the cookie has already been accepted, if so, don't show the bar.
    this.cookieIsAccepted() ? this.removeToast() : null;
  };

  // Functions called by DOM elements
  // --------------------------------------------------------------------------
  // This method is fired when a user accepts the cookie.
  accept = () => {
    this.writeAcceptCookie();
    this.removeToast();
  };

  // (Private) Helper Functions (don't call from outside this file)
  // --------------------------------------------------------------------------
  // Creates the cookie and sets it to expire in +30 days.
  writeAcceptCookie = () => {
    document.cookie = `gdprAccepted=true; ${this.cookieExpiryString()}`;
  };

  // Clears the cookie.
  clearAcceptCookie = () =>
    (document.cookie =
      "gdprAccepted=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/");

  // Check the cookie has been accepted in the past 30 days.
  cookieIsAccepted = () =>
    document.cookie.split(";").filter(c => c.trim().startsWith("gdprAccepted="))
      .length != 0;

  // Remove the DOM element representing the toast bar.
  removeToast = () => this.element.parentNode.removeChild(this.element);

  // "30 days from now" to cookie string.
  cookieExpiryString = () => {
    const exdays = 30;
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

    return `expires=${d.toUTCString()}; path=/`;
  };
}
