import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let options = {
      threshold: 1.0
    };

    if (
      !("IntersectionObserver" in window) ||
      !("IntersectionObserverEntry" in window) ||
      !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
    ) {
      this.element.style.width = "100%";
    } else {
      let observer = new IntersectionObserver(this.animate.bind(this), options);
      observer.observe(this.element);
    }
  }

  animate(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) this.element.classList.add("animated");
    });
  }
}
