import { Controller } from "stimulus";

const EXPANDED_CLASS = "account-reservation--expanded";
export default class extends Controller {
  toggleDetails(event) {
    event.preventDefault();

    if (this.element.classList.toggle(EXPANDED_CLASS)) {
      event.target.innerText = "Hide Trip Details";
    } else {
      event.target.innerText = "View Trip Details";
    }
  }
}
