import { Controller } from "stimulus";

const KEY_ESCAPE = 27;
const OPEN_CLASS = "modal--open";
const BODY_CLASS = "modal-open";
const MODAL_CLASS_SUCCESS = "success";

export default class extends Controller {
  connect() {
    if (this.isOpen()) {
      this.grabFocus();
      document.body.classList.add(BODY_CLASS);
    }
    document.addEventListener('modal:open', this.handlerModalOpenEvent.bind(this));
  }

  handlerModalOpenEvent(event) {
    const modelIdent = `modal:${event.detail.modal_id}`
    if (this.element.classList.contains(modelIdent)) {
      this.open();
    }
  }

  isOpen() {
    return this.element.classList.contains(OPEN_CLASS);
  }

  open() {
    document.body.classList.add(BODY_CLASS);
    this.element.classList.add(OPEN_CLASS);

    const event = new CustomEvent("modal-opened", { bubbles: true });
    this.element.dispatchEvent(event);

    this.grabFocus();
  }

  close(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    document.body.classList.remove(BODY_CLASS);
    this.element.classList.remove(OPEN_CLASS);
    if(this.element.classList.contains(MODAL_CLASS_SUCCESS)) {
      this.element.classList.remove(MODAL_CLASS_SUCCESS);
    }

    const event = new CustomEvent("modal-closed", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  keyHandler(event) {
    if (event.keyCode === KEY_ESCAPE) {
      this.close();
    }
  }

  // Move focus to dialog's first interactive element for a11y
  grabFocus() {
    const firstInput = this.element.querySelector(
      "input:not([type=hidden]),textarea"
    );
    if (firstInput) firstInput.focus();
  }

  backgroundClose(event) {
    // Ignore if target is a child element
    if (event.target === this.element) {
      this.close(event);
    }
  }
}
