import { Controller } from "stimulus";
import { submitForm } from "init/ajax";
import { parentModal } from "../modal/utils";

export default class extends Controller {
  static targets = ["country", "region"];

  connect() {
    this.changeCountry();
  }

  changeCountry() {
    let country_code = this.countryTarget.value;
    let $this = this;
    Cookie.setCookie("country_code", country_code, 1);
    Site.getRequest("/account/edit", e => {
      let html = document.createElement("html");
      html.innerHTML = JSON.parse(e.target.responseText)["html"];
      let localeFields = document.querySelector("[name='account_info[region]']").parentElement;
      let newLocaleFields = html.querySelector("[name='account_info[region]']").parentElement;
      localeFields.outerHTML = newLocaleFields.outerHTML;
    });
  }

  async submit(event) {
    event.preventDefault();

    const form = event.target;
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let responseHTML = await submitForm(form);

      parentModal(this.element).replaceWith(
        responseHTML.querySelector(".modal") || ""
      );
    }
  }
}
