import { Controller } from "stimulus";

export default class extends Controller {
  // Remove the modal if it won't be shown.
  connect = () => {
    return;
    if(this.isCookied()) { this.removeModal(); return; }
    this.addHiddenFieldForSIB();
    window.addEventListener("DOMContentLoaded", this.showDelayedModal);
  };

  addHiddenFieldForSIB = () => {
    const formEl = this.element.querySelector(".newsletter-form form");
    const authTokenEl = this.element.querySelector(
      "[name='authenticity_token']"
    );
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "source");
    input.setAttribute("value", "pop up");
    formEl.insertBefore(input, authTokenEl);
  };

  // Returns true if the expiry cookie is present.
  isCookied = () =>
    document.cookie
      .split(";")
      .filter(c => c.trim().startsWith("hasSeenModalOptin=")).length !== 0;

  // Delete the modal's DOM element and write the expiry cookie.
  hideWithCookie = () => {
    this.addCookie();
    this.removeModal();
  };

  // Create cookie w/magic number "3 days" expiry, could refactor to take "days".
  addCookie = () => this.writeCookie(this.daysFromTodayToDate(3));

  // Delete (write empty) cookie.
  clearCookie = () => this.writeCookie();

  // Helper function to interact with the cookie directly.
  writeCookie = (e = "Thu, 01 Jan 1970 00:00:00 GMT") => {
    document.cookie = `hasSeenModalOptin="true"; expires=${e}; path=/`;
  };

  // Adds the open class to the modal.
  showModal = () => {
    return;
    document.querySelector(".newsletter-modal").classList.add("modal--open");
    this.element.querySelector("[type=email]").focus();
  };

  // Calls showModal after a 10s delay.
  showDelayedModal = () => {
    if (!this.isCookied()) {
      setTimeout(() => {
        this.showModal();
        this.addCookie();
      }, 10000);
    }
  };

  // Delete modal's DOM element.
  removeModal = () => document.querySelector(".newsletter-modal").remove();

  // Returns a date, (days) from "now".
  daysFromTodayToDate = (days = 3) => {
    // Convert input from days to ms.
    const exms = days * 24 * 60 * 60 * 1000;

    const expDate = new Date().getTime() + exms;
    return new Date(expDate).toUTCString();
  };
}
