import {Controller} from "stimulus";

import {loadModalUrl} from "../modal/utils";

export default class extends Controller {
    modalLink(event) {
        event.preventDefault();

        loadModalUrl(event.target.href, {replace: this.element});
    }
}
