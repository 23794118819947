import { Controller } from "stimulus";
import { parentModal } from "../modal/utils";
import { submitForm } from "init/ajax";

export default class extends Controller {
  async submit(event) {
    event.preventDefault();
    const form = event.target;

    let responseHTML = await submitForm(form);

    parentModal(this.element).replaceWith(
      responseHTML.querySelector(".modal") || ""
    );
  }
}
