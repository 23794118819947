/**
 * Get current breakpoint name (mobile, tablet, desktop, etc)
 * @return {string} Breakpoint name
 */
// eslint-disable-next-line import/prefer-default-export
export function getBreakpoint() {
  return getComputedStyle(document.querySelector("body"), ":before")
    .getPropertyValue("content")
    .replace(/"/g, "");
}
