import { Controller } from "stimulus";

const ACTIVE_CLASS = "chat-bubble--active";
export default class extends Controller {
  connect = () => window.addEventListener("scroll", this.checkScrolled, true);

  // Add .mobile-show if not at the top of the page.
  checkScrolled = () => {
    Math.round(window.scrollY) > 0
      ? // Scrolled, show on mobile.
        this.element.classList.add("has-scrolled")
      : // At the top, don't show yet.
        this.element.classList.remove("has-scrolled");
  };

  toggle = () => {
    this.element.classList.toggle(ACTIVE_CLASS);
  };

  activateChat = e => {
    e.preventDefault();

    window.$trChat.show();
    this.toggle();
  };
}
