import { Controller } from "stimulus";

const REVEALED_CLASS = "-revealed";

export default class extends Controller {
  static targets = ["input"];

  setModified() {
    this.element.classList.add("-blurred");
  }

  toggleVisibility(event) {
    const button = event.currentTarget;
    const passwordField = this.inputTarget;

    if (passwordField) {
      if (button.classList.toggle(REVEALED_CLASS)) {
        passwordField.type = "text";
      } else {
        passwordField.type = "password";
      }
    }
  }


  updatePrice() {
    const currentValue = this.inputTarget.value;
    const priceBlock = this.inputTarget.closest(".wrap-input-price").querySelector(".price-wrap .price")
    const priceTargetDefault = this.inputTarget.getAttribute('price')
    const priceСurrency = priceTargetDefault.substring(0, 1)
    const priceDefault = parseFloat(this.inputTarget.getAttribute('price').slice(1));
    const result = currentValue * priceDefault;
    priceBlock.innerHTML = priceСurrency + result.toFixed(2);
  }

  increment() {
    this.inputTarget.stepUp();
    this.updatePrice();
  }

  decrement() {
    this.inputTarget.stepDown();
    this.updatePrice();
  }
}
