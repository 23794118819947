import { Controller } from "stimulus";
import { setCookie, deleteCookie } from "init/cookie";
import MicroModal from "micromodal";
import { DateTime } from "luxon";
import cart from "util/cart.js";
import unitQuote from "util/quote.js";
import { useDispatch } from "stimulus-use";
import order from "util/order";
import { errorMessageToReplace, errorMessageForReplacing } from './constants';
import { _moneyFormat } from "util/formatters";
import getFilters from "../../util/filter_params";


export default class extends Controller {
  static targets = [
    "dropdown",
    "dropdownBtn",
    "datesGuestsPicker",
    "adultsCount",
    "childrenCount",
    "arriveInput",
    "departInput",
    "datesLabelMobile",
    "datesLabel",
    "guestsCountMobile",
    "collapse",
    "rateInput",
    "rateName",
    "ratePrice",
    "bookingBtn",
    "priceBlock",
    "initialSidebarContent",
    "sidebarContent",
    "checkAvalabilityBtn",
    "arriveDateLabel",
    "departDateLabel",
    "guestsCount",
    "unitId",
    "errorsContainer",
    "feeItems",
    "feesTotal",
    "nightlyRate",
    "totalRate",
    "totalPrice",
    "pricePerGuest",
    "bottomBarTitle",
    "arriveDropdownBtn",
    "departDropdownBtn",
    "unitType",
    "addToCartBtn",
    "addedToCartBtn",
    "updateInCartBtn",
    "bookingBtnForm",
    "unitTypeId",
    "cartItemsCount",
    "rateNight",
    "tooltipText",
    "guestsWrapper",
    "totalPriceBlock",
    "pickOwnVillaBtn"
  ];

  currentQuote = undefined;

  connect() {
    useDispatch(this);
    const subHeader = document.querySelector(".sub-header-container");
    MicroModal.init({
      disableScroll: true,
      onShow: () => this.updateHeaderVisibility(subHeader),
      onClose: () => this.updateHeaderVisibility(subHeader)
    });
    // this.errorQuoteResponse = false;
    this.updateDates();
    this.updateGuestsCount();
    this.onlyOneCalendarDatePicked = false;

    if (this.isValidDates() && this.isValidDGuests()) {
        this.updateUrlAndSendQuoteRequest();
    } else {
        this.showAddToCartButton();
        this.disableAddToCartButton();
    }

    this.saveSearchDataToCookies();
    this.errorQuoteResponse = false;
    this.updateCartItemsCount();


    this.saveSearchDataToCookies();
    this.updateCartItemsCount();
  }

  handleItemQuoteResponse(item, response) {
    if (response.status === "error") {
      cart.remove(item.params.type, item.params.unit_id);
    }
  }

  handleMouseDown(e) {
    const isDropdown = this.dropdownTargets.find(dropdownTarget =>
      dropdownTarget.contains(e.target)
    );
    const isDropdownBtn = this.dropdownBtnTargets.find(dropdownBtnTarget =>
      dropdownBtnTarget.contains(e.target)
    );
    if (!isDropdown && !isDropdownBtn) {
      this.closeDropdowns();
    }
  }

  handleDatePickerChange() {
    this.onlyOneCalendarDatePicked = true;
    this.setActiveDatesDropdownBtn();
  }

  handleDatePickerClose() {
    this.closeDropdowns();
    this.handleDatesChanged();
    this.onlyOneCalendarDatePicked = false;
  }

  toggleDropdown(e) {
    const dropdownButton = e.currentTarget;
    const dropdown = this.dropdown(dropdownButton);

    this.dropdownTargets.forEach(dropdownTarget => {
      if (dropdownTarget === dropdown) return;
      this.closeDropdown(dropdownTarget);
    });
    dropdown.classList.toggle("open");
    if (dropdown.classList.contains("open")) {
      this.setActiveDropdownButton(dropdownButton);
    } else {
      this.resetActiveDropdowButtons();
    }
    this.updateGuestDatesPickerStyling();
  }

  closeDropdown(dropdown) {
    const dropdownBtns = this.dropdownButtons(dropdown);
    dropdown.classList.remove("open");
    dropdownBtns.forEach(dropdownBtn =>
      dropdownBtn.classList.remove("not-active", "active")
    );
  }

  resetActiveDropdowButtons() {
    this.dropdownBtnTargets.forEach(dropdownBtn =>
      dropdownBtn.classList.remove("active", "not-active")
    );
  }

  updateCartItemsCount() {
    const cartItemsCount = cart.items().length;

    if (cartItemsCount > 0) {
      this.cartItemsCountTargets.forEach(item => {
        item.classList.remove("hidden");
        item.textContent = cartItemsCount;
      });
    } else {
      this.cartItemsCountTargets.forEach(item => {
        item.classList.add("hidden");
        item.textContent = "";
      });
    }
  }

  dropdown(sibling) {
    const siblings = [...sibling.parentNode.children].filter(
      c => c !== sibling
    );
    const dropdown = this.dropdownTargets.find(dropdownTarget =>
      siblings.includes(dropdownTarget)
    );
    return dropdown;
  }

  dropdownButtons(sibling) {
    const siblings = [...sibling.parentNode.children].filter(
      c => c !== sibling
    );
    const dropdownButtons = this.dropdownBtnTargets.filter(dropdownBtnTarget =>
      siblings.includes(dropdownBtnTarget)
    );
    return dropdownButtons;
  }

  closeDropdowns() {
    this.dropdownTargets.forEach(dropdownTarget => {
      this.closeDropdown(dropdownTarget);
    });
    this.updateGuestDatesPickerStyling();
  }

  setActiveDropdownButton(activeDropdownBtn) {
    this.dropdownBtnTargets.forEach(dropdownBtn => {
      dropdownBtn.classList.remove("active");
      dropdownBtn.classList.add("not-active");
    });

    if (
      activeDropdownBtn === this.arriveDropdownBtnTarget ||
      activeDropdownBtn === this.departDropdownBtnTarget
    ) {
      this.setActiveDatesDropdownBtn();
    } else {
      activeDropdownBtn.classList.remove("not-active");
      activeDropdownBtn.classList.add("active");
    }
  }

  setActiveDatesDropdownBtn() {
    if (this.onlyOneCalendarDatePicked) {
      this.arriveDropdownBtnTarget.classList.remove("active");
      this.arriveDropdownBtnTarget.classList.add("not-active");
      this.departDropdownBtnTarget.classList.remove("not-active");
      this.departDropdownBtnTarget.classList.add("active");
    } else {
      this.departDropdownBtnTarget.classList.remove("active");
      this.departDropdownBtnTarget.classList.add("not-active");
      this.arriveDropdownBtnTarget.classList.remove("not-active");
      this.arriveDropdownBtnTarget.classList.add("active");
    }
  }

  updateDates() {
    const filters = getFilters({
      checkin: this.arriveInputTarget.value,
      checkout: this.departInputTarget.value
    }, false);

    if (!filters.has('checkin') || !filters.has('checkout')) {
      return;
    }

    // replace '-' with '/' to avoid effecting timezone when creating date object from a string
    const arriveDate = DateTime.fromJSDate(
      new Date(filters.get('checkin').replace(/-/g, "/"))
    );
    const departDate = DateTime.fromJSDate(
      new Date(filters.get('checkout').replace(/-/g, "/"))
    );
    const arriveDateFull = arriveDate.toFormat("MMM d, yyyy");
    const departDateFull = departDate.toFormat("MMM d, yyyy");
    const arriveDateShort = arriveDate.toFormat("MM/dd/yy");
    const departDateShort = departDate.toFormat("MM/dd/yy");
    this.arriveDateLabelTarget.textContent = arriveDateFull;
    this.departDateLabelTarget.textContent = departDateFull;
    this.datesLabelMobileTarget.textContent = `${arriveDateShort} - ${departDateShort}`;
    this.updateSubmitButtonState();
    this.updateCartButtonState();
  }

  updateGuestsCount() {
    const filters = getFilters({
      adults: this.adultsCountTarget.value,
      children: this.childrenCountTarget.value
    }, false);

    this.adultsCountTarget.value = filters.get('adults') || 2;
    this.childrenCountTarget.value = filters.get('children') || 0;

    const adultsCount = Number(filters.get('adults') || 2);
    const childrenCount = Number(filters.get('children') || 0);
    const adultsWording = adultsCount === 1 ? "Adult" : "Adults";
    const childrenWording = childrenCount === 1 ? "Child" : "Children";
    const guestsWording =
      adultsCount + childrenCount === 1 ? "Guest" : "Guests";
    let guestsCountText = "Select Guests";

    if (adultsCount || childrenCount) {
      guestsCountText = `
        ${adultsCount +
          childrenCount} ${guestsWording} (${adultsCount} ${adultsWording}, ${childrenCount} ${childrenWording})
         `;
    }
    this.guestsCountTarget.textContent = guestsCountText;
    this.guestsCountMobileTarget.textContent = `${adultsCount +
      childrenCount} ${guestsWording}`;
    this.updateSubmitButtonState();
    this.updateCartButtonState();
  }

  updateGuestDatesPickerStyling() {
    const openedDropdown = this.dropdownTargets.find(dropdownTarget =>
      dropdownTarget.classList.contains("open")
    );
    if (openedDropdown) {
      this.datesGuestsPickerTarget.classList.add("sharp-corners");
    } else {
      this.datesGuestsPickerTarget.classList.remove("sharp-corners");
    }
  }

  resetDates() {
    this.datesLabelMobileTarget.textContent = `Select Dates`;
    this.arriveDateLabelTarget.textContent = `Check in`;
    this.departDateLabelTarget.textContent = `Check out`;
    window.location.reload();
  }

  toggleCollapse(e) {
    const collapse = this.collapseTargets.find(collapseTarget =>
      collapseTarget.contains(e.currentTarget)
    );
    collapse.classList.toggle("expanded");
  }

  updateSubmitButtonState() {

    if (this.errorQuoteResponse) {
      return;
    }

    if (!this.isValidDates()) {
      this.bookingBtnTarget.value = "Choose Dates";
      this.disableProceedToBookingButton();
      return;
    }

    if (!this.isValidDGuests()) {
      this.bookingBtnTarget.value = "Enter # of Guests";
      this.disableProceedToBookingButton();
      return;
    } else {
      this.guestsWrapperTarget.classList.remove("restricted-guests");
      this.enableProceedToBookingButton();
      this.enableAddToCartButton();
      this.enablePriceBlocks();
    }
  }

  isValidDates() {
    return !!(this.arriveInputTarget.value && this.departInputTarget.value);
  }

  isValidDGuests() {
    return !!Number(this.adultsCountTarget.value);
  }

  disableProceedToBookingButton() {
    this.bookingBtnTarget.classList.add("disabled-button");
  }

  addDisableAttributeProceedToBookingButton() {
    this.bookingBtnTarget.setAttribute("disabled", "");
  }

  removeDisableAttributeProceedToBookingButton() {
    this.bookingBtnTarget.removeAttribute("disabled");
  }

  disablePriceBlocks() {
    this.totalPriceBlockTarget.classList.add("disabled");
    this.priceBlockTarget.classList.add("disabled");
  }

  enablePriceBlocks() {
    this.totalPriceBlockTarget.classList.remove("disabled");
    this.priceBlockTarget.classList.remove("disabled");
  }

  enableProceedToBookingButton() {
    this.bookingBtnTarget.classList.remove("disabled-button");
    this.bookingBtnTarget.value = "Book Now";
  }

  enableAddToCartButton() {
    this.addToCartBtnTarget.removeAttribute("disabled", "");
    this.updateInCartBtnTarget.removeAttribute("disabled", "");
    this.addedToCartBtnTarget.removeAttribute("disabled", "");
  }

  disableAddToCartButton() {
    this.addToCartBtnTarget.setAttribute("disabled", "");
    this.updateInCartBtnTarget.setAttribute("disabled", "");
    this.addedToCartBtnTarget.setAttribute("disabled", "");
  }

  showAddToCartButton() {
    this.addedToCartBtnTarget.classList.add("hidden");
    this.updateInCartBtnTarget.classList.add("hidden");
    this.addToCartBtnTarget.classList.remove("hidden");
  }

  showAddedToCartButton() {
    this.addToCartBtnTarget.classList.add("hidden");
    this.updateInCartBtnTarget.classList.add("hidden");
    this.addedToCartBtnTarget.classList.remove("hidden");
  }

  showUpdateInCartButton() {
    this.addToCartBtnTarget.classList.add("hidden");
    this.addedToCartBtnTarget.classList.add("hidden");
    this.updateInCartBtnTarget.classList.remove("hidden");
  }

  updateCartButtonState() {
    if (
      this.errorQuoteResponse ||
      !this.isValidDates() ||
      !this.isValidDGuests()
    ) {
      this.disableAddToCartButton();
      return;
    }

    const bookigItemFromCart = cart.item(
      this.unitTypeTarget.value,
      this.unitIdTarget.value
    );

    this.enableAddToCartButton();

    if (
      bookigItemFromCart &&
      JSON.stringify(bookigItemFromCart) ===
        JSON.stringify(this.bookingItemParams())
    ) {
      this.showAddedToCartButton();
      this.updateCartItemsCount();
    } else if (bookigItemFromCart) {
      this.showUpdateInCartButton();
      this.updateCartItemsCount();
    } else {
      this.showAddToCartButton();
      this.updateCartItemsCount();
    }
  }

  urlQueryParams() {
    const adultsCount = parseInt(this.adultsCountTarget.value, 10);
    const childrenCount = parseInt(this.childrenCountTarget.value, 10);
    const checkIn = this.arriveInputTarget.value;
    const checkOut = this.departInputTarget.value;

    return {
      ...(checkIn && { checkin: checkIn }),
      ...(checkOut && { checkout: checkOut }),
      ...(adultsCount !== 0 && { adults: adultsCount }),
      ...(childrenCount !== 0 && { children: childrenCount })
    };
  }

  bookingItemParams() {
    return {
      unit_id: this.unitIdTarget.value,
      type: this.unitTypeTarget.value,
      unit_type_id: this.unitTypeIdTarget.value,
      arrival_date: this.arriveInputTarget.value,
      departure_date: this.departInputTarget.value,
      adults: this.adultsCountTarget.value,
      children: this.childrenCountTarget.value,
      total: this.totalPrice
    };
  }

  updateUrlAndSendQuoteRequest() {
    const prevUrl = window.location.href;
    const queryParams = this.urlQueryParams();
    const query = Site.objectToQuery(queryParams);
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query}`;

    // stop from repeat requests to Track API
    if (!Site.quoteStored || prevUrl !== url) {
      /* eslint-disable-next-line no-restricted-globals */
      if (history.pushState) {
        window.history.pushState({ path: url }, "", url);
      } else {
        window.location.href = url;
      }
      this.sendQuoteRequest();
    }
  }

  handleDatesChanged() {
    this.updateUrlAndSendQuoteRequest();
    this.saveDatesToCookies();
  }

  handleGuestsChanged() {
    const queryParams = this.urlQueryParams();
    const query = Site.objectToQuery(queryParams);
    let url;
    if (query) {
      url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query}`;
    } else {
      url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    }

    /* eslint-disable-next-line no-restricted-globals */
    if (history.pushState) {
      window.history.pushState({ path: url }, "", url);
    } else {
      window.location.href = url;
    }
    this.updateGuestsCount();
    this.updatePricePerGuest();
    this.saveGuestsToCookies();
  }

  saveDatesToCookies() {
    const arrive = this.arriveInputTarget.value;
    const depart = this.departInputTarget.value;

    if (!arrive || !depart) return;

    const dates = {
      checkin: arrive,
      checkout: depart
    };
    setCookie("filter_by_dates", JSON.stringify(dates), 3);
  }

  saveGuestsToCookies() {
    const adultsCount = this.adultsCountTarget.value;
    const childrenCount = this.childrenCountTarget.value;

    if (!adultsCount && !childrenCount) return;

    const guests = {
      adults: adultsCount,
      children: childrenCount
    };
    setCookie("filter_by_guests", JSON.stringify(guests), 3);
  }

  saveSearchDataToCookies() {
    this.saveDatesToCookies();
    this.saveGuestsToCookies();
  }

  clearErrorMessage() {
    this.errorsContainerTarget.innerText = "";
    this.errorsContainerTarget.classList.add("hidden");
  }

  setErrorMessage(message) {
    this.errorsContainerTarget.innerText = message === errorMessageToReplace
      ? errorMessageForReplacing
      : message;
    this.errorsContainerTarget.classList.remove("hidden");
  }

  async sendQuoteRequest() {
    const result = await unitQuote(this.bookingItemParams());
    this.handleQuoteResponse(result);
  }

  handleQuoteResponse(response) {
    this.clearErrorMessage();
    if (response.status && response.status === "error") {
      this.setErrorMessage(response.message);
      this.disablePriceBlocks();
      this.disableProceedToBookingButton();
      this.addDisableAttributeProceedToBookingButton();
      this.disableAddToCartButton();
      this.errorQuoteResponse = true;
    } else {
      this.errorQuoteResponse = false;
      this.currentQuote = response;
      this.updateQuote(response);
      this.removeDisableAttributeProceedToBookingButton();
      this.updateSubmitButtonState();
      this.pushDataLayer(response);
    }
  }

  showRateAndSetName(name) {
    if (!this.hasRateNameTarget) return;

    this.rateNameTargets.forEach(target => {
      target.textContent = name;
    });
  }

  updateFees(feeItems) {
    this.feeItemsTarget.innerHTML = "";
    let feesSum = 0;
    feeItems.forEach(item => {
      const feePrice = parseFloat(item.value);
      const feeItemHtml = `
                <div class="fee-item">
                    <div class="fee-item-name">
                        <span>${item.name}</span>
                    </div>
                    <span>${_moneyFormat(feePrice)}</span>
                </div>
            `;
      feesSum += parseFloat(item.value);
      this.feeItemsTarget.insertAdjacentHTML("beforeend", feeItemHtml);
    });
    this.feesTotalTarget.textContent = `${_moneyFormat(feesSum)}`;
  }

  updateNightlyAndTotalRate(nightlyRate, numNights, totalRate) {
    this.nightlyRateTarget.textContent = `${_moneyFormat(nightlyRate)} x ${numNights} nights`;
    if (this.hasRateNightTarget) {
      this.rateNightTarget.textContent = `${_moneyFormat(nightlyRate)} / nights`;
    }
    this.totalRateTarget.textContent = `${_moneyFormat(totalRate)}`;

    // for unit type only
    if (this.hasRatePriceTarget) {
      this.ratePriceTargets.forEach(target => {
        target.textContent = `${_moneyFormat(nightlyRate)} / night`;
      });
    }
  }

  updateHeaderVisibility(subHeader) {
    if (subHeader instanceof Element) {
      const computedStyle = window.getComputedStyle(subHeader);
      if (computedStyle.display === "none") {
        subHeader.style.display = "";
      } else {
        subHeader.style.display = "none";
      }
    }
  }

  updatePricePerGuest() {
    const guestsCount =
      parseInt(this.adultsCountTarget.value, 10) +
      parseInt(this.childrenCountTarget.value, 10);
    let text;
    const options = { maximumFractionDigits: 2 };

    if (!this.totalPrice || guestsCount === 0) {
      text = "$--";
    } else {
      const pricePerGuestPerNight = this.totalPrice / guestsCount;
      text = _moneyFormat(pricePerGuestPerNight);
    }
    this.pricePerGuestTarget.textContent = text;
  }

  updateTotalPrice(total) {
    this.totalPrice = parseFloat(total);
    this.totalPriceTarget.textContent = _moneyFormat(this.totalPrice);
    this.bottomBarTitleTarget.textContent = `Total before taxes: ${_moneyFormat(this.totalPrice)}`;
    this.updateSubmitButtonState();
    this.updateCartButtonState();
  }

  updateQuote(response) {
    // since the response did't return sooner, assume success
    /* eslint-disable-next-line no-undef */
    Site.quoteStored = true;

    const {
      guest_fees: feeItems,
      nightly_rate: nightlyRate,
      num_nights: numNights,
      total_rate: totalRate,
      total_taxes: taxes,
      rate_name: rateName,
      total
    } = response;

    const totalWithoutTaxes = total - taxes;

    this.updateFees(feeItems);
    this.updateNightlyAndTotalRate(nightlyRate, numNights, totalRate);
    this.updateTotalPrice(totalWithoutTaxes);
    this.updatePricePerGuest();
    this.showRateAndSetName(rateName);
    this.updateSubmitButtonState();
  }

  handleAddToCart() {
    cart.add(this.bookingItemParams());
    this.pushCartDataLayer('add_to_cart');
    this.updateCartButtonState();
    window.dispatchEvent(new CustomEvent("cartUpdated"));
  }

  handleUpdateInCart() {
    const unitId = this.unitIdTarget.value;
    const unitType = this.unitTypeTarget.value;
    cart.update(unitType, unitId, this.bookingItemParams());
    this.updateCartButtonState();
    window.dispatchEvent(new CustomEvent("cartUpdated"));
  }

  handleClickTooltip() {
    const isVisible = this.tooltipTextTarget.style.visibility === "visible";
    this.tooltipTextTarget.style.visibility = isVisible ? "hidden" : "visible";
  }

  handleUnitType(unitType) {
    if (unitType !== "unit_type") return false;

    const modalId = "pick-own-villa-modal";
    const modal = document.getElementById(modalId);

    if (modal && !modal.classList.contains("is-open")) {
      MicroModal.show(modalId);
      return true;
    }

    return false;
  }

  handleDisabledButton() {
    const isDisabled = this.bookingBtnTarget.classList.contains(
      "disabled-button"
    );

    if (!isDisabled) return false;

    if (!this.isValidDates()) {
      this.arriveDateLabelTarget.click();
      return true;
    }

    if (!this.isValidDGuests()) {
      this.guestsCountMobileTarget.click();
      this.guestsWrapperTarget.classList.add("restricted-guests");
      return true;
    }
  }

  async handleProceedToBooking(e) {
    e.preventDefault();

    const unitId = this.unitIdTarget.value;
    const unitType = this.unitTypeTarget.value;
    const isItemPresentInCart = cart.item(unitType, unitId);
    const buttonForm = e.currentTarget;

    if (this.handleDisabledButton()) return;
    // commented bc we hide popup
    // if (this.handleUnitType(unitType)) return;

    order.create([this.bookingItemParams()]);

    try {
      const itemsWithQuotes = await cart.itemsWithQuotes();
      itemsWithQuotes.forEach(item => {
        this.handleItemQuoteResponse(item, item.quote);
      });
    } catch (error) {
      console.error("Error checking availability of cart items:", error);
    }
    buttonForm.submit();
  }

  pushDataLayer(quote) {
    if (!window.unitLayer) return;
    let data = { ...window.unitLayer };
    data.cartTotal = quote.total;
    data.ecommerce.total_quantity = quote.num_nights;
    data.ecommerce.price_per_night = quote.nightly_rate;

    const totalGuests = this.adultsCountTarget.value + this.childrenCountTarget.value
    data.ecommerce.number_of_guests = totalGuests;
    window.dataLayer.push(data);

    let dataGTM = { ...window.unitLayerGTM }
    dataGTM.ecommerce.items[0].price = quote.nightly_rate;
    window.dataLayer.push(dataGTM);
  }

  pushCartDataLayer(event_name) {
    const eventData = { ...window.unitLayerGTM }
    const code = window.unitLayer.ecommerce.house_sku;
    eventData.event_name = event_name
    eventData.ecommerce.items[0].price = this.currentQuote.nightly_rate
    eventData.ecommerce.items[0].quantity = this.currentQuote.num_nights
    this.currentQuote.guest_fees.forEach(fee => {
      eventData.ecommerce.items.push({
        item_id: `${code}-${fee.name}`,
        item_name: fee.name,
        item_brand: 'Villatel',
        item_category: 'Fee',
        price: fee.unit_value,
        quantity: fee.quantity
      })
    })
    window.dataLayer.push(eventData);
  }
}
