import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("click", this.closeAllSelects);
    this.selectTarget = this.element.querySelector("select");
    // ensure autocomplete fills in dropdowns
    this.selectTarget.addEventListener("change", e => {
      const el = e.currentTarget.selectedOptions[0];
      this.selectOption(el);
    });
    this.buildSelected();
    this.buildOptionList();
  }

  click(e) {
    e.stopPropagation();
    this.closeAllSelects(e.target.parentNode);
    this.element.classList.toggle("open");
    this.optionsListWrapperTarget.classList.toggle("select-hide");
    this.element.dispatchEvent(new CustomEvent("toggle-select"));
  }

  closeAllSelects(el) {

    document.querySelectorAll(".custom-select, .destinations").forEach(select => {
      if (select !== el) {
        select.classList.remove("open");
        const selectItemsWrapper = select.querySelector(".select-items-wrapper")
        if (selectItemsWrapper) {
          selectItemsWrapper.classList.add("select-hide");
        }
          
        select.dispatchEvent(new CustomEvent("toggle-select"));
      }
    });
  }

  selectOption(el) {
    const index = Array.from(this.optionListTarget.children).indexOf(el);
    const changed = this.selectItem.innerHTML !== el.innerHTML;
    this.selectTarget.selectedIndex = index;
    this.selectItem.innerHTML = el.innerHTML;
    this.optionsListWrapperTarget.classList.add("select-hide");
    if (changed) {
      this.element.dispatchEvent(new CustomEvent("change"));
    }
  }

  buildSelected() {
    this.selectItem = document.createElement("DIV");
    const selectEl = this.selectTarget;
    const selectText = selectEl.options[selectEl.selectedIndex].innerHTML;
    this.selectItem.setAttribute("class", "select-selected");
    this.selectItem.innerHTML = selectText;
    this.element.appendChild(this.selectItem);
    this.selectItem.addEventListener("click", this.click.bind(this));
  }

  buildOptionList() {
    this.optionsListWrapperTarget = document.createElement("div");
    this.optionsListWrapperTarget.setAttribute(
      "class",
      "select-items-wrapper select-hide"
    );
    this.optionListTarget = document.createElement("UL");
    this.optionListTarget.setAttribute("class", "select-items");
    Array.from(this.selectTarget.options).forEach(option => {
      const listItem = document.createElement("LI");
      listItem.innerHTML = option.innerHTML;
      if (this.selectItem.innerHTML === option.innerHTML) {
        listItem.setAttribute("class", "selected");
      }
      if (option.disabled) {
        listItem.setAttribute("class", "disabled");
        listItem.addEventListener("click", e => {
          e.stopPropagation();
        });
      } else {
        listItem.addEventListener("click", e => {
          this.selectOption(e.target);
        });
      }
      this.optionListTarget.appendChild(listItem);
    });
    this.optionsListWrapperTarget.appendChild(this.optionListTarget);
    this.element.appendChild(this.optionsListWrapperTarget);
  }
}
