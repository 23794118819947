import { Controller } from "stimulus";
import { submitForm } from "init/ajax";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  submit(e) {
    e.preventDefault();
    e.returnValue = false;

    submitForm(this.formTarget).then(response => {
      if( response.status === 'ok') {
        alert('Thank You!');
      }
    })
  }
}
